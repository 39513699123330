import React, { useEffect } from 'react'
import { AppConfigurator } from './fields/AppConfigurator'
import {
  SimpleBridgeBuilderCard,
  SimpleBridgeBuilderCardSeparator,
  SimpleBridgeBuilderWrapper,
} from './styled'
import { AiOutlinePlus } from 'react-icons/ai'
import { useHandleAppsCompatibility } from '@features/nbee/SimpleBridgeBuilderForm/appsCompatibility/useHandleAppsCompatibility'
import { useFormikContext } from 'formik'
import { BridgeFormValues, AppIntegrationSetting } from 'Nbee'
import { EmailReceipt } from '@features/nbee/EmailReceipt'
import { ApiUserModuleItem, ApiUser } from 'BackendApi'

interface FormInnerProps {
  emailReceiptUserModule?: ApiUserModuleItem
  userData?: ApiUser
  templateSettings?: AppIntegrationSetting[]
}

export const FormInner: React.FC<FormInnerProps> = ({
  emailReceiptUserModule,
  userData,
  templateSettings,
}) => {
  const { appsAreCompatible, emailFeatureAvailable } =
    useHandleAppsCompatibility()
  const { values, isValid, isSubmitting, setFieldValue } =
    useFormikContext<BridgeFormValues>()

  const bridgeId = values?.ui?.bridgeId

  useEffect(() => {
    if (emailFeatureAvailable !== undefined) {
      // update formik context with email feature availability
      setFieldValue('settings.emailReceipt.canShow', emailFeatureAvailable)
    }
  }, [emailFeatureAvailable])

  return (
    <SimpleBridgeBuilderWrapper>
      {/* SOURCE */}
      <SimpleBridgeBuilderCard
        hasError={values.ui?.source?.hasSettingsApiError}
      >
        <AppConfigurator
          type='source'
          canShowIntegrationField={appsAreCompatible}
          templateSettings={templateSettings}
        />
      </SimpleBridgeBuilderCard>
      {/* SEPARATOR */}
      <SimpleBridgeBuilderCardSeparator>
        <AiOutlinePlus size={'1.5rem'} />
      </SimpleBridgeBuilderCardSeparator>
      {/* DESTINATION */}
      <SimpleBridgeBuilderCard
        hasError={values.ui?.destination?.hasSettingsApiError}
      >
        <AppConfigurator
          type='destination'
          canShowIntegrationField={appsAreCompatible}
        />
        <EmailReceipt
          bridgeId={bridgeId}
          emailReceiptUserModule={emailReceiptUserModule}
          userData={userData}
        />
      </SimpleBridgeBuilderCard>
    </SimpleBridgeBuilderWrapper>
  )
}
